


import Component from 'vue-class-component';
import {Prop, Vue, Watch} from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TContact } from '@/_types/contact.type';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TMessage } from '@/_types/messages.type';
import Avatar from '@/_components/avatar/avatar.vue';
import IconAllRead from '@/_modules/icons/components/sidebar/icon-all-read.vue';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import IconNoCorrespondence from '@/_modules/icons/components/sidebar/icon-no-correspondence.vue';
import SideBarContactInfo from '@/_modules/promo/components/side-bar-contact-info/side-bar-contact-info.vue';
import { TranslateResult } from 'vue-i18n';
import {Action, Getter} from 'vuex-class';

@Component({
  components: {
    Avatar,
    SideBarContactInfo,
    IconAllRead,
    IconNoCorrespondence,
    IconArrowLeft
  },
  computed: {
    ...mapGetters({
      user: '_userStore/user',
      event: '_eventStore/event',
      promoPage: 'promoPageStore/promoPage',
      isOpen: 'sideBarRightStore/isOpen',
      activeTabName: 'sideBarRightStore/activeTabName',
      isAuthenticated: 'authStore/isAuthenticated',
      messages: '_messageStore/messages',
    }),
  },
})
export default class SideBarRightTabCorrespondence extends Vue {
  @Getter('sideBarRightStore/selectedMessageByContactId') selectedMessageByContactId: number;
  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName: string }) => void;

  public tabSearchString: string = '';
  public viewingContactId: number = 0;

  public readonly user: TUser;
  public readonly event: TEvent;
  public readonly promoPage: TPromoPage;
  public readonly isAuthenticated: boolean;
  public readonly messages: TApiListResponse<TMessage>;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get isProfileVisible(): boolean {
    return this.viewingContactId > 0;
  }

  @Prop({ type: String, default: '' })
  public readonly tabContentTitle: TranslateResult;

  @Watch('selectedMessageByContactId', {immediate: true})
  onSelectedMessageByContactIdChanged(): void{
    if(this.selectedMessageByContactId) {
      this.viewingContactId = this.selectedMessageByContactId;
    }
  }

  public created(): void {
    this.getUserCorrespondence();
  }

  private async getUserCorrespondence(): Promise<void> {
    if (!this.user) {
      setTimeout(() => {
        this.getUserCorrespondence();
      }, 1000);
      return;
    }

    await this.$store.dispatch('_messageStore/callMessageList', {
      eventId: this.eventId,
      userId: this.user.id,
      limit: 1000
    });
  }

  private getDataCSSSearchAttributeValue(message: TMessage): string {
    if (!message || !message.contact) {
      return '';
    }

    const data: string[] = [message.contact.name, message.contact.surname].filter(x => x).map((x: string) => x.toLowerCase());

    return data.join('');
  }

  private filterCorrespondence(): void {
    const styleId = 'cssSearcherSideBarCorrespondence';
    const val = this.tabSearchString.toLowerCase().trim();
    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    let cssSearcher: HTMLStyleElement | null = document.getElementById(styleId) as HTMLStyleElement;
    if (!cssSearcher) {
      cssSearcher = document.createElement('style');
      cssSearcher.setAttribute('type', 'text/css');
      cssSearcher.id = styleId;
      cssSearcher.setAttribute('rel', 'stylesheet');
      head.appendChild(cssSearcher);
    }
    if (val === '') {
      cssSearcher.innerHTML = '';
    } else {
      cssSearcher.innerHTML = '.tab-content-correspondence .messages-list .message-preview { display: none !important; }';
      cssSearcher.innerHTML += '.tab-content-correspondence .messages-list .message-preview[data-csssearch*="' + val + '"] { display: flex !important; }';
    }
  }

  private viewProfileByMessage(message: TMessage): void {
    this.openContactCard({
      contactId: message.contact.id,
      startupTabName: 'messages',
    });
  }

  private truncateMessageSnippet(messageText: string): string {
    const limit = 43;
    let result: string = messageText;
    if (messageText.length > limit) {
      result = messageText.substring(0, limit) + '...';
    }
    return result;
  }

  private getUnreadCount(message: TMessage): string {
    let result = '';
    if (message.contact.unread_messages) {
      result = message.contact.unread_messages.toFixed(0);
      if (message.contact.unread_messages > 99) {
        result = '99+';
      }
    }
    return result;
  }

  private getContactInitials(contact: TContact): string {
    const defaultInitialsText = '';
    if (!contact) {
      return defaultInitialsText;
    } else if (!contact.name || !contact.surname) {
      return defaultInitialsText;
    }
    return contact.name.substring(0, 1).toUpperCase() + contact.surname.substring(0, 1).toUpperCase();
  }

}
