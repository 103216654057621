


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TContact } from '@/_types/contact.type';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import Avatar from '@/_components/avatar/avatar.vue';
import messages from '@/_modules/promo/components/messages/messages.vue';
import NotesList from '@/views/components/promoPage/notes/notesList.vue';
import { TNote } from '@/_types/store/note.type';
import ContactSchedule from '@/_modules/contacts/components/contact-schedule/contact-schedule.vue';

@Component({
  components: {
    Avatar,
    messages,
    ContactSchedule,
    NotesList
  },
  computed: {
    ...mapGetters({
      user: '_userStore/user',
      event: '_eventStore/event',
      promoPage: 'promoPageStore/promoPage',
      isAuthenticated: 'authStore/isAuthenticated',
      contactById: 'contactsStore/contactById',
      contactNotes: 'sideBarRightStore/contactNotes'
    })
  },
})
export default class SideBarContactInfo extends Vue {

  public tabSearchString: string = '';
  public activeTab: string = 'messages';
  public isLoadingContact: boolean = false;

  public readonly user: TUser;
  public readonly event: TEvent;
  public readonly promoPage: TPromoPage;
  public readonly isAuthenticated: boolean;
  public readonly contactById: (contactId: number) => TContact;
  public readonly contactNotes: TNote[];

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get currentContact(): TContact {
    return this.contactById(this.contactId);
  }

  public get currentContactInitials(): string {
    const defaultInitialsText = '';
    if (!this.currentContact) {
      return defaultInitialsText;
    } else if (!this.currentContact.name || !this.currentContact.surname) {
      return defaultInitialsText;
    }
    return this.currentContact.name.substring(0, 1).toUpperCase() + this.currentContact.surname.substring(0, 1).toUpperCase();
  }

  public get currentContactFullName(): string {
    if (!this.currentContact) {
      return '';
    }
    let result = [this.currentContact.name, this.currentContact.surname].filter(x => x).join(' ');
    if (!result) {
      result = this.$t('contacts.info.noname') as string;
    }
    return result;
  }

  public get currentContactGeoInfo(): string {
    if (!this.currentContact) {
      return '';
    }
    return [this.currentContact.city, this.currentContact.country].filter(x => x).join(', ');
  }

  @Prop({ type: Number, default: 0 })
  public readonly contactId: number;

  @Watch('activeTab')
  private onActiveTabChange(newValue: string): void {
    if(newValue === 'notes') {
      this.requestContactNotes();
    }
  }

  @Watch('contactId')
  private onContactIdChange(newVal: number, oldVal: number): void {
    if (newVal !== oldVal) {
      this.requestContactNotes();
    }
  }

  public async created(): Promise<void> {
    this.isLoadingContact = true;
    await this.$store.dispatch('contactsStore/requestContact', {
      contactId: this.contactId,
      force: true,
    });
    this.isLoadingContact = false;
  }

  private selectTab(tabName: string): void {
    this.activeTab = tabName;
  }

  private async requestContactNotes(): Promise<void> {
    if (!this.currentContact) {
      window.setTimeout(() => {
        this.requestContactNotes();
      }, 250);
      return;
    }
    await this.$store.dispatch('sideBarRightStore/getContactNotes', {
      eventId: this.eventId,
      queryParams: {
        user_id: this.currentContact.user.id
      }
    });
  }

}
