


import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class OnlineIndicator extends Vue {
  @Getter('contactsStore/isContactOnline') isContactOnline: (contactId: number) => boolean;

  @Prop({ type: Number, default: 0 })
  public contactId: number;

  public get isOnline(): boolean {
    return this.isContactOnline(this.contactId);
  }

}

