


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { TranslateResult } from 'vue-i18n';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TEvent } from '@/_types/event.type';
import SingleRowCalendar from '@/_modules/single-row-calendar/single-row-calendar.vue';
import {
  TDateSchedule,
  TMeeting,
  TMonthSchedule,
  TScheduleItem,
  ScheduleItemType,
} from '@/_types/meeting/meeting.type';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import { TUser } from '@/_types/user.type';
import { TContact } from '@/_types/contact.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import { MeetingStatus } from '@/_modules/meeting-rooms/types/meeting-status.enum';
import Avatar from '@/_components/avatar/avatar.vue';
import AddToCalendar from '@/_components/add-to-calendar/add-to-calendar.vue';
import MeetingsHelper from '@/_helpers/meetings.helper';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import DateTimeHelper from '@/_helpers/date-time.helper';
import _isEqual from 'lodash.isequal';
import MeetingsHead from '@/_modules/meetings/components/meetings-head/meetings-head.vue';
import { TScheduleDay } from '@/_modules/single-row-calendar/types/single-row-calendar.type';
import Person from '@/_modules/contacts/components/person/person.vue';
import IconVideoCall from '@/_modules/icons/components/sidebar/icon-video-call.vue';

@Component({
  name: 'side-bar-right-tab-schedule',
  components: {
    Avatar,
    AddToCalendar,
    MeetingsHead,
    SingleRowCalendar,
    Person,
    IconVideoCall
  },
  computed: {
    ...mapGetters({
      user: '_userStore/user',
      event: '_eventStore/event',
      contact: 'promoPageStore/contact',
      conferenceRooms: 'promoProgramStore/conferenceRooms',
      getMeetingsByUserId: 'meetingsStore/getMeetingsByUserId',
      getIsMeetingsLoadingByUserId: 'meetingsStore/getIsLoadingByUserId',
      isUserLoading: '_userStore/isLoading',
      isContactLoading: 'promoPageStore/isContactLoading',
      isEventLoading: '_eventStore/isLoading',
      isProgramLoading: 'promoProgramStore/isLoading',
      getSelectedDate: 'meetingsStore/getSidebarScheduleSelectedDate'
    }),
  },
})
export default class SideBarRightTabSchedule extends Vue {

  public readonly ScheduleItemType: typeof ScheduleItemType = ScheduleItemType;

  public readonly user: TUser;
  public readonly event: TEvent;
  public readonly contact: TContact;
  public readonly conferenceRooms: TConferenceRoom[];
  public readonly getMeetingsByUserId: (userId: number) => TMeeting[];
  public readonly getIsMeetingsLoadingByUserId: (userId: number) => boolean;
  public readonly isUserLoading: boolean;
  public readonly isEventLoading: boolean;
  public readonly isProgramLoading: boolean;
  public readonly isContactLoading: boolean;

  public schedule: TMonthSchedule[] = null;
  public selectedMonthSchedule: TMonthSchedule = null;

  public meetingsAreDifferentAfterUpdate: boolean = true;
  public confRoomsAreDifferentAfterUpdate: boolean = true;
  public scheduleRedrawFlag: boolean = false;

  private now: moment.Moment;
  private scheduleByDate: Map<string, TDateSchedule> = new Map<string, TDateSchedule>();
  private destroyed$: Subject<void> = new Subject<void>();
  private updateSchedule$: Subject<void> = new Subject<void>();

  public eventDays: TDateSchedule[] = [];
  public getSelectedDate: TDateSchedule;

  public nowTimestamp: number = (new Date()).getTime();
  public nowTimestampUpdaterIntervalId: number = null;

  public created(): void {
    this.updateSchedule$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(200),
    ).subscribe(() => {
      this.updateSchedule();
    });

    this.now = this.$moment();

    this.updateSchedule$.next();
  }

  public mounted(): void {
    this.startNowTimestampUpdate();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.stopNowTimestampUpdate();
  }

  public get isScheduleRedrawNecessary(): boolean {
    return this.scheduleRedrawFlag || this.meetingsAreDifferentAfterUpdate || this.confRoomsAreDifferentAfterUpdate;
  };

  public set isScheduleRedrawNecessary(value: boolean) {
    this.scheduleRedrawFlag = value;
  };

  public get userId(): number {
    return (this.user && this.user.id) || null;
  }

  public get eventId(): number {
    return (this.event && this.event.id) || null;
  }

  public get contactId(): number {
    return (this.contact && this.contact.id) || null;
  }

  public get formattedDate(): string {
    return this.getSelectedDate &&
      this.getSelectedDate.date &&
      this.$moment(this.getSelectedDate.date).format('dddd, DD MMMM');
  }

  public get isMeetingsLoading(): boolean {
    const userId = this.userId;
    if (!userId) {
      return false;
    }
    return this.getIsMeetingsLoadingByUserId(userId);
  }

  public get meetings(): TMeeting[] {
    const userId = this.userId;
    if (!userId) {
      return [];
    }
    return this.getMeetingsByUserId(userId);
  }

  public get selectedDate(): TDateSchedule {
    return this.getSelectedDate;
  }

  public get isLoading(): boolean {
    if (!this.isScheduleRedrawNecessary) {
      return false;
    }
    return this.isProgramLoading
      || this.isEventLoading
      || this.isContactLoading
      || this.isUserLoading
      || this.isMeetingsLoading;
  }

  public get isEmptyCurrentSchedule(): boolean {
    return !this.selectedMonthSchedule
      || !this.selectedMonthSchedule.selectedItem
      || !this.selectedMonthSchedule.selectedItem.items
      || !this.selectedMonthSchedule.selectedItem.items.length;
  }

  public get selectedDateSchedule(): TDateSchedule { // FIXME: ?
    return (this.selectedMonthSchedule && this.selectedMonthSchedule.selectedItem) || null;
  }

  public get currentItems(): TScheduleItem[] {
    return (
      this.selectedMonthSchedule
      && this.selectedMonthSchedule.selectedItem
      && this.selectedMonthSchedule.selectedItem.items
    ) || [];
  }

  public get selectedMonthName(): string {
    return this.selectedMonthSchedule && this.$moment(this.selectedMonthSchedule.month).format('MMMM');
  }

  public get scheduleItemsPastTimeMark(): number {
    // Current time - 30 minutes, as specified in AW-1779
    // See isTimeSlotPast for comparison
    return this.nowTimestamp - (1000 * 60 * 30);
  }

  public set scheduleItemsPastTimeMark(value: number) {
    this.nowTimestamp = value;
  }

  public getAddToCalendarStart(item: TScheduleItem): Date {
    switch (item.type) {
      case ScheduleItemType.MEETING:
        return this.$moment(item.meeting.date_start).toDate();

      case ScheduleItemType.PROGRAM:
        return this.$moment(item.program.date_start).toDate();
    }

    return null;
  }

  public getAddToCalendarEnd(item: TScheduleItem): Date {
    switch (item.type) {
      case ScheduleItemType.MEETING:
        return this.$moment(item.meeting.date_end).toDate();

      case ScheduleItemType.PROGRAM:
        return this.$moment(item.program.date_end).toDate();
    }

    return null;
  }

  public getAddToCalendarTitle(item: TScheduleItem): TranslateResult {
    switch (item.type) {
      case ScheduleItemType.MEETING: {
        const contactId = this.contactId;
        if (item.meeting.creator_contact.id !== contactId) {
          return this.$t('[\'add-to-calendar\'].meeting.title', {
            name: (item.meeting.creator_contact && item.meeting.creator_contact.fullName) || 'noname',
          });
        } else {
          return this.$t('[\'add-to-calendar\'].meeting.title', {
            name: (item.meeting.user_contact && item.meeting.user_contact.fullName) || 'noname',
          });
        }
      }

      case ScheduleItemType.PROGRAM:
        return item.program.title;
    }

    return null;
  }

  public getAddToCalendarDetails(item: TScheduleItem): TranslateResult {
    switch (item.type) {
      case ScheduleItemType.MEETING:
        return this.$t('[\'add-to-calendar\'].meeting.details', {
          link: this.getMeetingShareUrl(item.meeting),
        });

      case ScheduleItemType.PROGRAM:
        return this.$t('[\'add-to-calendar\'].program.details', {
          link: location.origin + this.$router.resolve({
            name: 'promo-program-date-program',
            params: {
              date: this.$moment(item.program.date_start).format(DateTimeFormat.DATE_TINY),
              programId: '' + item.program.id,
            }
          }).href,
        });
    }

    return null;
  }

  public getAddToCalendarLocation(item: TScheduleItem): TranslateResult {
    switch (item.type) {
      case ScheduleItemType.MEETING:
        return this.$t('[\'add-to-calendar\'].meeting.location');

      case ScheduleItemType.PROGRAM:
        return this.$t('[\'add-to-calendar\'].program.location');
    }

    return null;
  }

  public getScheduleItemAvatarSrc(scheduleItem: TScheduleItem): string {
    switch (scheduleItem.type) {
      case ScheduleItemType.MEETING: {
        const contactId = this.contactId;
        if (scheduleItem.meeting) {
          if (scheduleItem.meeting.user_contact.id !== contactId) {
            return scheduleItem.meeting.user_contact.photo_url;
          } else {
            return scheduleItem.meeting.creator_contact.photo_url;
          }
        }
        break;
      }
    }

    return null;
  }

  public getScheduleItemLocation(scheduleItem: TScheduleItem): RawLocation {
    switch (scheduleItem.type) {
      case ScheduleItemType.MEETING: {
        const contactId = this.contactId;
        if (scheduleItem.meeting) {
          if (scheduleItem.meeting.user_contact.id !== contactId) {
            return {
              name: 'promo-page-contacts-contact',
              params: { contact_id: '' + scheduleItem.meeting.user_contact.id }
            };
          } else {
            return {
              name: 'promo-page-contacts-contact',
              params: { contact_id: '' + scheduleItem.meeting.creator_contact.id }
            };
          }
        }
        break;
      }

      case ScheduleItemType.PROGRAM: {
        return {
          name: 'promo-program-date-program',
          params: {
            date: this.$moment(scheduleItem.program.date_start).format(DateTimeFormat.DATE_TINY),
            programId: '' + scheduleItem.program.id,
          }
        };
      }
    }

    return '';
  }

  public getScheduleItemTitle(scheduleItem: TScheduleItem): string {
    switch (scheduleItem.type) {
      case ScheduleItemType.MEETING: {
        const contactId = this.contactId;
        if (scheduleItem.meeting) {
          if (scheduleItem.meeting.user_contact.id !== contactId) {
            return scheduleItem.meeting.user_contact.fullName;
          } else {
            return scheduleItem.meeting.creator_contact.fullName;
          }
        }
        break;
      }

      case ScheduleItemType.PROGRAM: {
        return scheduleItem.program.title;
      }
    }

    return null;
  }

  public getScheduleItemDescription(scheduleItem: TScheduleItem): string {
    switch (scheduleItem.type) {
      case ScheduleItemType.MEETING: {
        const contactId = this.contactId;
        if (scheduleItem.meeting) {
          if (scheduleItem.meeting.user_contact.id !== contactId) {
            return scheduleItem.meeting.user_contact.fullCompany;
          } else {
            return scheduleItem.meeting.creator_contact.fullCompany;
          }
        }
        break;
      }

      case ScheduleItemType.PROGRAM: {
        return scheduleItem.program.description;
      }
    }

    return null;
  }

  // public selectDateSchedule(dateSchedule: TDateSchedule): void {
  //   if (!this.selectedMonthSchedule) {
  //     return;
  //   }
  //   this.selectedMonthSchedule.selectedItem = dateSchedule;
  // }

  public onStartCallClick(item: TScheduleItem): void {
    if (item.type !== ScheduleItemType.MEETING) {
      return;
    }
    const nowTimestamp = (new Date()).getTime();
    if (item.meeting.date_start.getTime() - nowTimestamp > (1000 * 60 * 5)) {
      this.$store.dispatch('meetingsStore/setMeetingTooEarlyPopupVisible', true);
      return;
    }
    const meetingRoomConfig = {
      type: 'meeting',
      eventId: this.eventId,
      meetingId: item.meeting.id,
      contactId: this.contactId,
      meetingDate: this.$moment(item.meeting.date_start).unix(),
    };
    this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
  }

  public onMonthLeftArrowClick(): void {
    if (!this.schedule || !this.selectedMonthSchedule) {
      return;
    }
    const index = this.schedule.indexOf(this.selectedMonthSchedule);
    if (index - 1 >= 0) {
      this.selectedMonthSchedule = this.schedule[index - 1];
    }
  }

  public onMonthRightArrowClick(): void {
    if (!this.schedule || !this.selectedMonthSchedule) {
      return;
    }
    const index = this.schedule.indexOf(this.selectedMonthSchedule);
    if (index + 1 < this.schedule.length) {
      this.selectedMonthSchedule = this.schedule[index + 1];
    }
  }

  public onDateLeftArrowClick(): void {
    if (!this.schedule || !this.selectedMonthSchedule || !this.selectedMonthSchedule.selectedItem) {
      return;
    }
    const index = this.selectedMonthSchedule.items.indexOf(this.selectedMonthSchedule.selectedItem);
    if (index - 1 >= 0) {
      this.selectedMonthSchedule.selectedItem = this.selectedMonthSchedule.items[index - 1];
    }
  }

  public onDateRightArrowClick(): void {
    if (!this.schedule || !this.selectedMonthSchedule || !this.selectedMonthSchedule.selectedItem) {
      return;
    }
    const index = this.selectedMonthSchedule.items.indexOf(this.selectedMonthSchedule.selectedItem);
    if (index + 1 < this.selectedMonthSchedule.items.length) {
      this.selectedMonthSchedule.selectedItem = this.selectedMonthSchedule.items[index + 1];
    }
  }

  @Watch('user', { immediate: true })
  private onUserChange(): void {
    this.isScheduleRedrawNecessary = true; // AW-1853
    this.updateSchedule$.next();
  }

  @Watch('contact', { immediate: true })
  private onContactChange(): void {
    this.isScheduleRedrawNecessary = true; // AW-1853
    this.updateSchedule$.next();
  }

  @Watch('event', { immediate: true })
  private onEventChange(): void {
    this.isScheduleRedrawNecessary = true; // AW-1853
    this.generateEmptySchedule();
    this.updateSchedule$.next();
  }

  @Watch('conferenceRooms', { immediate: true })
  private onConferenceRoomsChange(newVal: TConferenceRoom[], oldVal: TConferenceRoom[]): void {
    if (Array.isArray(newVal) && Array.isArray(oldVal) && _isEqual(newVal, oldVal)) {
      this.confRoomsAreDifferentAfterUpdate = false;
    } else {
      this.confRoomsAreDifferentAfterUpdate = true;
      this.isScheduleRedrawNecessary = true;
      this.updateSchedule(true);
    }
    this.updateSchedule$.next();
  }

  @Watch('meetings', { immediate: true })
  private onMeetingsChange(newVal: TMeeting[], oldVal: TMeeting[]): void {
    if (Array.isArray(newVal) && Array.isArray(oldVal) && _isEqual(newVal, oldVal)) {
      this.meetingsAreDifferentAfterUpdate = false;
    } else {
      this.meetingsAreDifferentAfterUpdate = true;
      this.isScheduleRedrawNecessary = true;
      this.updateSchedule(true);
    }
    this.updateSchedule$.next();
  }

  @Watch('selectedDate')
  private onSelectedDateChange(): void {
    if (this.$moment(this.selectedMonthSchedule.selectedItem.date).format() !== this.$moment(this.selectedDate.date).format()) {
      this.isScheduleRedrawNecessary = true;
      this.selectedMonthSchedule.selectedItem = this.selectedDate;
      this.updateSchedule(true);
    }
  }

  @Watch('isScheduleRedrawNecessary')
  private onIsScheduleRedrawNecessaryChange(newVal: boolean): void {
    if (newVal) {
      this.updateSchedule(true);
    }
  }

  private getMeetingShareUrl(meeting: TMeeting): string {
    return MeetingsHelper.getMeetingInviteUrl({
      type: MeetingRoomType.MEETING,
      eventId: this.eventId,
      meetingId: meeting.id,
      meetingDate: this.$moment(meeting.date_start).unix(),
    });
  }

  private generateEmptySchedule(): void {
    this.schedule = null;
    this.selectedMonthSchedule = null;
    this.scheduleByDate.clear();

    if (
      !this.event
      || !this.event.date_start
      || !this.event.date_end
      || this.$moment(this.event.date_end).isBefore(this.event.date_start)
    ) {
      return;
    }

    const dateStartMoment = this.$moment(this.event.date_start);
    const dateEndMoment = this.$moment(this.event.date_end);
    const dateMoment = dateStartMoment.clone().hours(12).minutes(0).seconds(0);
    let monthSchedule: TMonthSchedule;
    let selectedMonthSchedule: TMonthSchedule = null;
    this.schedule = [];
    this.eventDays = [];

    let days_diff = DateTimeHelper.getDateDiff(dateEndMoment.toDate(), dateStartMoment.toDate());

    do {
      if (!monthSchedule || !monthSchedule.month.isSame(dateMoment, 'month')) {
        if (monthSchedule && !monthSchedule.selectedItem) {
          monthSchedule.selectedItem = monthSchedule.items[0];
        }
        const monthScheduleMonthMoment = dateMoment.clone().date(1);
        monthSchedule = {
          month: monthScheduleMonthMoment,
          monthKey: monthScheduleMonthMoment.format('MM'),
          items: [],
          selectedItem: null,
        };
        if (monthSchedule.month.isSame(this.now, 'month')) {
          selectedMonthSchedule = monthSchedule;
        }
        this.schedule.push(monthSchedule);
      }

      const dateScheduleDate = dateMoment.clone();
      const dateKey = dateScheduleDate.format(DateTimeFormat.DATE_TINY);
      const dateSchedule: TDateSchedule = {
        month: dateScheduleDate.month() + 1,
        monthName: this.$moment(dateScheduleDate).format('MMMM'),
        week: this.$moment(dateScheduleDate).week(),
        date: dateScheduleDate,
        dayNumber: dateScheduleDate.date(),
        dateKey: dateKey,
        items: [],
      };
      if (dateSchedule.date.isSame(this.now, 'date')) {
        monthSchedule.selectedItem = dateSchedule;
        this.$store.dispatch('meetingsStore/sidebarScheduleDate', { ...dateSchedule });
      }
      monthSchedule.items.push(dateSchedule);

      this.eventDays.push(dateSchedule);
      this.scheduleByDate.set(dateKey, dateSchedule);

      dateMoment.add(1, 'day');

      days_diff--;
    } while (days_diff >= 0);

    if (!this.selectedDate.date) {
      monthSchedule.selectedItem = monthSchedule.items[0];
      this.$store.dispatch('meetingsStore/sidebarScheduleDate', ...[monthSchedule.items[0]]);
    }

    if (!selectedMonthSchedule) {
      selectedMonthSchedule = this.schedule[0];
    }

    this.selectedMonthSchedule = selectedMonthSchedule || null;
  }

  private emptifySchedule(): void {
    this.scheduleByDate.forEach(dateSchedule => {
      dateSchedule.items = [];
    });
  }

  private updateSchedule(force: boolean = false): void {
    if (this.schedule === null) {
      this.generateEmptySchedule();
    } else {
      if (this.isScheduleRedrawNecessary === false && !force) {
        return;
      }
      this.emptifySchedule();
    }

    const contactId = this.contactId;
    const meetings = this.meetings;

    if (this.conferenceRooms && this.conferenceRooms.length) {
      this.conferenceRooms.forEach(conferenceRoom => {
        if (!conferenceRoom.programs || !conferenceRoom.programs.length) {
          return;
        }
        conferenceRoom.programs.forEach(program => {
          if (
            program.is_favorite
            || (program.speakers && program.speakers.find(speaker => speaker.id === contactId))
          ) {
            const dateKey = this.$moment(program.date_start).format(DateTimeFormat.DATE_TINY);
            const dateSchedule = this.scheduleByDate.get(dateKey);
            if (dateSchedule) {
              dateSchedule.items.push({
                type: ScheduleItemType.PROGRAM,
                startTime: this.$moment(program.date_start),
                startTimeKey: this.$moment(program.date_start).format(DateTimeFormat.DATE_MEDIUM),
                startTimeText: this.$moment(program.date_start).format(DateTimeFormat.HOURS_MINUTES),
                endTime: this.$moment(program.date_end),
                endTimeKey: this.$moment(program.date_end).format(DateTimeFormat.DATE_MEDIUM),
                endTimeText: this.$moment(program.date_end).format(DateTimeFormat.HOURS_MINUTES),
                key: `program-${program.id}`,
                program: program,
                markerText: this.$t('promo.schedule.Program'),
              });
            }
          }
        });
      });
    }

    if (meetings && meetings.length) {
      meetings.forEach(meeting => {
        if (
          meeting.status === MeetingStatus.Confirmed
          && (meeting.creator_contact.id === contactId || meeting.user_contact.id === contactId)
          && !(meeting.creator_contact.id === contactId && meeting.user_contact.id === contactId)
        ) {
          const dateStartMoment = this.$moment(meeting.date_start);
          const dateEndMoment = this.$moment(meeting.date_end);
          const dateKey = dateStartMoment.format(DateTimeFormat.DATE_TINY);
          const dateSchedule = this.scheduleByDate.get(dateKey);
          if (dateSchedule) {
            dateSchedule.items.push({
              type: ScheduleItemType.MEETING,
              startTime: dateStartMoment,
              startTimeKey: dateStartMoment.format(DateTimeFormat.DATE_MEDIUM),
              startTimeText: dateStartMoment.format(DateTimeFormat.HOURS_MINUTES),
              endTime: dateEndMoment,
              endTimeKey: dateEndMoment.format(DateTimeFormat.DATE_MEDIUM),
              endTimeText: dateEndMoment.format(DateTimeFormat.HOURS_MINUTES),
              key: `meeting-${meeting.id}`,
              meeting: meeting,
              markerText: this.$t('promo.schedule.Meeting'),
            });
          }
        }
      });
    }

    /* sort inside single date */
    this.scheduleByDate.forEach(dateSchedule => {
      dateSchedule.items.sort((a, b) => {
        if (a.startTimeKey === b.startTimeKey) {
          if (a.endTimeKey === b.endTimeKey) {
            return 0;
          } else if (a.endTimeKey > b.endTimeKey) {
            return 1;
          } else {
            return -1;
          }
        } else if (a.startTimeKey > b.startTimeKey) {
          return 1;
        } else {
          return -1;
        }
      });
    });

    this.isScheduleRedrawNecessary = false; // see AW-1853
  }

  private startNowTimestampUpdate(): void {
    this.nowTimestampUpdaterIntervalId = window.setInterval(() => {
      this.scheduleItemsPastTimeMark = (new Date()).getTime();
    }, 1000 * 60);
  }

  private stopNowTimestampUpdate(): void {
    window.clearInterval(this.nowTimestampUpdaterIntervalId);
  }

  private isScheduleItemCurrent(item: TScheduleItem): boolean {
    if (!item.startTime || !item.endTime) {
      return false;
    }

    const dateStart = item.startTime.toDate();
    const dateEnd = item.endTime.toDate();

    return dateStart.getTime() < this.nowTimestamp
      && dateEnd.getTime() > this.nowTimestamp;
  }

  private isScheduleItemPast(item: TScheduleItem): boolean {
    if (!item.endTime) {
      return false;
    }

    return item.endTime.toDate().getTime() < this.scheduleItemsPastTimeMark;
  }

  public targetScheduleDay(day: TScheduleDay): void { // FIXME: адаптация под однострочный календарь

    const meetings: TScheduleItem[] = [];
    this.meetings.forEach(meeting => {
      const dateStartMoment = this.$moment(meeting.date_start);
      const dateEndMoment = this.$moment(meeting.date_end);
      const dateStart = new Date(meeting.date_start);
      dateStart.setHours(0);
      dateStart.setMinutes(0);

      if (meeting.status === 'confirmed' && +dateStart === +day.fullDate) {
        meetings.push(
          {
            type: ScheduleItemType.MEETING,
            startTime: dateStartMoment,
            startTimeKey: dateStartMoment.format(DateTimeFormat.DATE_MEDIUM),
            startTimeText: dateStartMoment.format(DateTimeFormat.HOURS_MINUTES),
            endTime: dateEndMoment,
            endTimeKey: dateEndMoment.format(DateTimeFormat.DATE_MEDIUM),
            endTimeText: dateEndMoment.format(DateTimeFormat.HOURS_MINUTES),
            key: `meeting-${meeting.id}`,
            meeting: meeting,
            markerText: this.$t('promo.schedule.Meeting'),
          }
        );
      }
    });

    const dateSchedule: TDateSchedule = {
      month: this.$moment(day.fullDate).month() + 1,
      monthName: day.monthName,
      week: this.$moment(day.weekNumber).week(),
      date: this.$moment(day.fullDate),
      dayNumber: +day.dayNumber,
      dateKey: day.dayName,
      items: [...meetings],
    };

    this.$store.dispatch('meetingsStore/sidebarScheduleDate', { ...dateSchedule });
  }

}
