


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TContact } from '@/_types/contact.type';
import { TEvent } from '@/_types/event.type';

@Component({
  components: {},
})
export default class MenuHintsGuide extends Vue {

  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('promoPageStore/contact') contactInfo: TContact;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('authStore/isAuthPopupVisible') isAuthPopupVisible: boolean;
  @Getter('_userStore/isMenuGuideShown') isMenuGuideShown: boolean;

  public isOpenAccount: boolean = true;
  public isOpenSchedule: boolean = false;
  public isOpenMessages: boolean = false;
  public isOpenNotifications: boolean = false;

  public mounted(): void {
    window.addEventListener('load', () => {
      if (this.isMenuGuideShown) {
        this.setParams(0);
      }
    });

  }

  public openHint(value: string): void {
    switch (value) {
      case 'account':
        this.isOpenAccount = !this.isOpenAccount;
        break;
      case 'schedule':
        this.isOpenSchedule = true;
        this.setParams(1);
        break;
      case 'messages':
        this.isOpenMessages = true;
        this.setParams(2);
        break;
      case 'notifications':
        this.isOpenNotifications = true;
        this.setParams(0);
        break;
      default:
        break;
    }
  }

  public setParams(iconIndex: number): void {
    const layer: NodeListOf<HTMLFormElement> = document.querySelectorAll('.layer');
    const sideBarIcons: NodeListOf<HTMLFormElement> = document.querySelectorAll('.has-tooltip');
    const iconTop = sideBarIcons[iconIndex].getBoundingClientRect().top;
    const iconHeight = sideBarIcons[iconIndex].getBoundingClientRect().height;

    layer[0].style.height = iconTop + 'px';
    layer[1].style.top = iconTop + 'px';
    layer[2].style.top = iconTop + iconHeight + 'px';
  }

  public closeHint(): void {
    const layer: NodeListOf<HTMLFormElement> = document.querySelectorAll('.layer');

    layer[0].style.height = '';
    layer[1].style.top = '';
    layer[2].style.top = '';

    this.$store.dispatch('_userStore/menuUserGuideSwitcher', false);
  }
}
