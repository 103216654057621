import { render, staticRenderFns } from "./contact-info-inviter.html?vue&type=template&id=be5c6a8e&scoped=true&"
import script from "./contact-info-inviter.vue?vue&type=script&lang=ts&"
export * from "./contact-info-inviter.vue?vue&type=script&lang=ts&"
import style0 from "./contact-info-inviter.scss?vue&type=style&index=0&id=be5c6a8e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be5c6a8e",
  null
  
)

export default component.exports