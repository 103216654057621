


import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import _isEqual from 'lodash.isequal';
import { TranslateResult } from 'vue-i18n';
import { MENU_PROPERTIES_KEY_NAME } from '@/_modules/promo-cabinet/components/cabinet-event-settings/cabinet-event-settings.vue';
import NotificationsMixin from '@/_mixins/notifications.mixin.ts';
import { TEvent, TEventSettings } from '@/_types/event.type';

import iconHome from '@/_modules/icons/components/sidebar/icon-home.vue';
import iconInfo from '@/_modules/icons/components/sidebar/icon-info.vue';
import IconProgram from '@/_modules/icons/components/sidebar/icon-program.vue';
import iconHall from '@/_modules/icons/components/sidebar/icon-hall.vue';
import iconContacts from '@/_modules/icons/components/sidebar/icon-contacts.vue';
import iconMeetings from '@/_modules/icons/components/sidebar/icon-meetings.vue';
import iconDiscussions from '@/_modules/icons/components/sidebar/icon-discussions.vue';
import iconNotes from '@/_modules/icons/components/sidebar/icon-notes.vue';
import iconResult from '@/_modules/icons/components/sidebar/icon-result.vue';
import iconTextChats from '@/_modules/icons/components/sidebar/icon-text-chats.vue';
import iconNews from '@/_modules/icons/components/sidebar/icon-news.vue';
import IconHelp from '@/_modules/icons/components/sidebar/icon-help.vue';
import UtilsHelper from '@/_helpers/utils.helper';
import HelpCrunchService from '@/_services/help-crunch.service';

export type TSideBarLeftMenuItem = {
  routeName?: string;
  customActionName?: string;
  routeQuery?: any;
  active: boolean;
  isActive?: () => boolean;
  isDisabled?: () => boolean;
  title?: TranslateResult;
  iconComponentName?: string;
  sorting?: number;
  isSorted?: boolean;
}

@Component({
  components: {
    iconHome,
    iconInfo,
    IconProgram,
    iconHall,
    iconContacts,
    iconMeetings,
    iconDiscussions,
    iconNotes,
    iconResult,
    iconTextChats,
    iconNews,
    IconHelp
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      isEventLoading: '_eventStore/isLoading',
      eventSettings: '_eventStore/eventSettings',
      isEventSettingsPolled: '_eventStore/isEventSettingsPolled',
      isAuthenticated: 'authStore/isAuthenticated',
      meetingsCount: 'notificationsStore/meetingsCount',
      noticedMeetingsCount: 'notificationsStore/noticedMeetingsCount',
      messagesCount: 'notificationsStore/messagesCount',
      noticedMessagesCount: 'notificationsStore/noticedMessagesCount',
    }),
  },
})
export default class PromoSideBarLeft extends mixins(NotificationsMixin) {

  public menuItems: TSideBarLeftMenuItem[] = [
    {
      routeName: 'event-info',
      active: true,
      isActive: (): boolean => this.$route.name === 'event-info',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.info',
      iconComponentName: 'icon-home',
    },
    {
      routeName: 'promo-live',
      active: true,
      isActive: (): boolean => this.$route.name === 'promo-live',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'custom_online-exporf.sideBar.live',
      iconComponentName: 'icon-info',
    },
    {
      routeName: 'news',
      active: true,
      isActive: (): boolean => this.$route.name === 'news',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.news',
      iconComponentName: 'icon-news',
    },
    {
      routeName: 'promo-program',
      active: true,
      isActive: (): boolean => { return this.$route.meta && this.$route.meta.isProgramRoute; },
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.program',
      iconComponentName: 'icon-program',
    },
    {
      routeName: 'promo-page-events-companies',
      active: true,
      isActive: (): boolean => (this.$route.name.indexOf('promo-page-events') === 0 || this.$route.name === 'promo-profile') && (this.$route.name !== 'promo-contacts'),
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.hall',
      iconComponentName: 'icon-hall',
    },
    {
      routeName: 'promo-contacts',
      active: true,
      isActive: (): boolean => (this.$route.name.indexOf('promo-page-contacts') === 0) || (this.$route.name === 'promo-contacts') || (this.$route.name.indexOf('promo-contact-') > -1),
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.contacts',
      iconComponentName: 'icon-contacts',
      routeQuery: {},
    },
    {
      routeName: 'text-chats',
      active: true,
      isActive: (): boolean => this.$route.name === 'text-chats' || this.$route.name === 'text-chat-expanded',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.textChats',
      iconComponentName: 'icon-text-chats',
    },
    {
      routeName: 'notes',
      active: true,
      isActive: (): boolean => this.$route.name === 'notes',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.notes',
      iconComponentName: 'icon-notes',
    },
    {
      routeName: 'promo-page-calendar',
      active: true,
      isActive: (): boolean => this.$route.name.indexOf('promo-page-calendar') > -1,
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.calendar',
      iconComponentName: 'icon-meetings',
    },
    {
      routeName: 'result',
      active: true,
      isActive: (): boolean => this.$route.name === 'result-personal' || this.$route.name === 'result-company' || this.$route.name === 'result-event',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.result',
      iconComponentName: 'icon-result',
    },
    {
      customActionName: 'help',
      active: true,
      isActive: (): boolean => false,
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.help',
      iconComponentName: 'icon-help',
    },
  ];

  public readonly event: TEvent;
  public readonly isEventLoading: boolean;
  public readonly isAuthenticated: boolean;
  public readonly meetingsCount: number;
  public readonly noticedMeetingsCount: number;
  public readonly messagesCount: number;
  public readonly eventSettings: TEventSettings;
  public readonly isEventSettingsPolled: boolean;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get sortedMenuItems(): TSideBarLeftMenuItem[] {

    this.menuItems.forEach((x, index) => {
      if (this.getMenuItemSorting(x)) {
        this.menuItems[index].sorting = this.getMenuItemSorting(x);
        if (this.getMenuItemIsSorted(x) !== undefined) {
          this.menuItems[index].isSorted = this.getMenuItemIsSorted(x);
        }
      }
    });

    return this.menuItems.sort((a, b) => {
      if (a.sorting < b.sorting) {
        return -1;
      } else if (a.sorting > b.sorting) {
        return 1;
      }
      return 0;
    });
  }

  public isMenuItemHavingDividerBefore(menuItem: TSideBarLeftMenuItem): boolean {
    const index = this.activeMenuItems.findIndex(item => item === menuItem);
    if (index === 0) {
      return false;
    }
    const previousItem = this.activeMenuItems[index - 1];
    return menuItem.isSorted === false && previousItem.isSorted !== false;
  }

  public get activeMenuItems(): TSideBarLeftMenuItem[] {
    return this.sortedMenuItems.filter(x => x.active && this.isMenuItemShown(x));
  }

  @Prop({ type: Boolean, default: false })
  public readonly accessCheckNecessary: boolean;

  public mounted(): void {
    this.checkEventIsEnabled();
    this.$store.dispatch('sideBarLeftStore/close');
  }

  private checkEventIsEnabled(): void {

    if (this.$route.name === 'event-info') {
      return;
    }

    if (!this.event || !this.event.personal) {
      window.setTimeout(() => {
        this.checkEventIsEnabled();
      }, 250);
      return;
    }
    if (this.isEventAccessEnabled() === false) {
      this.handleEventAccessDisabled();
    }
  }

  private async handleEventAccessDisabled(): Promise<void> {
    await this.$store.dispatch('eventStore/showEventAccessDisabledPopup');

    try {
      this.$router.push({
        name: 'event-info',
        params: { eventId: this.$route.params.eventId }
      });
    } catch {
      /* ignore */
    }
  }

  private isEventAccessEnabled(): boolean {
    return this.event.personal.is_creator || this.event.is_enabled;
  }

  private isRouteQueryEqual(menuItem: TSideBarLeftMenuItem): boolean {
    return (!this.$route.query || !menuItem.routeQuery || _isEqual(this.$route.query, menuItem.routeQuery));
  }

  private menuItemClickHandler(event: Event, item: TSideBarLeftMenuItem): void {

    event.preventDefault();

    if (item.customActionName) {
      this.dispatchCustomAction(item);
      return;
    }

    const routeName: string = item.routeName;

    if (!routeName || ((routeName === this.$route.name) && this.isRouteQueryEqual(item))) {
      return;
    }

    this.$emit('promoGoto', routeName);

    // For showPromoAccessPopup
    if (this.accessCheckNecessary) {
      return;
    }

    try {
      this.$router.push({
        name: routeName,
        params: { eventId: this.$route.params.eventId },
        query: item.routeQuery || undefined,
      }).catch(e => e);
    } catch {
      /* ignore */
    }
  }

  private dispatchCustomAction(menuItem: TSideBarLeftMenuItem): void {
    if (menuItem.customActionName === 'help') {
      this.openSupportChat();
    }
  }

  private hoverHandler(event: MouseEvent): void {
    if (!event || !event.type) {
      return;
    }

    if (event.type === 'mouseenter') {
      this.$store.dispatch('sideBarLeftStore/open');
    } else if (event.type === 'mouseleave') {
      this.$store.dispatch('sideBarLeftStore/close');
    }

  }

  private isMenuItemShown(menuItem: TSideBarLeftMenuItem): boolean {
    if (!this.eventSettings || !this.eventSettings.layout || !this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME]) {
      return true;
    }
    const menuItemRouteNameSnakeCase = UtilsHelper.routeNamesToSettingsKeys(menuItem.routeName); // AW-2349
    if (!Object.prototype.hasOwnProperty.call(this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME], menuItemRouteNameSnakeCase)) {
      return true;
    }

    return this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME][menuItemRouteNameSnakeCase].isShown;

  }

  private getMenuItemSorting(menuItem: TSideBarLeftMenuItem): number {
    const menuItemRouteNameSnakeCase = UtilsHelper.routeNamesToSettingsKeys(menuItem.routeName); // AW-2349
    if (this.eventSettings && !Object.prototype.hasOwnProperty.call(this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME], menuItemRouteNameSnakeCase)) {
      return null;
    }
    return this.eventSettings && this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME][menuItemRouteNameSnakeCase].sorting;
  }

  private getMenuItemIsSorted(menuItem: TSideBarLeftMenuItem): boolean {
    const menuItemRouteNameSnakeCase = UtilsHelper.routeNamesToSettingsKeys(menuItem.routeName);
    if (this.eventSettings && !Object.prototype.hasOwnProperty.call(this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME], menuItemRouteNameSnakeCase)) {
      return null;
    }
    return this.eventSettings && this.eventSettings.layout[MENU_PROPERTIES_KEY_NAME][menuItemRouteNameSnakeCase].isSorted;
  }

  private isCalendarBadgeNotificationNeeded(item: TSideBarLeftMenuItem): boolean {
    return (item.routeName === 'promo-page-calendar') && (this.meetingsCount - this.noticedMeetingsCount > 0);
  }

  private openSupportChat(): void {
    const helpCrunchInstance = HelpCrunchService._helpCrunch;
    if (!helpCrunchInstance) {
      window.setTimeout(() => { this.openSupportChat(); }, 1000);
      return;
    }
    helpCrunchInstance('openChat');
  }

  public getMenuItemHref(menuItem: TSideBarLeftMenuItem): string {
    if (menuItem.customActionName) {
      return null; // N.B.: null here means that href won't be present in the DOM
    }
    return this.$router.resolve({
      name: menuItem.routeName,
      params: {
        eventId: this.$route.params.eventId
      }
    }).href;
  }

}
