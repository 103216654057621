


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { TEventDay } from '@/_types/meeting/meeting.type';
import { Getter } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import IconChevronLeft from '@/_modules/icons/components/single-row-calendar/chevron-left.vue';
import IconChevronRight from '@/_modules/icons/components/single-row-calendar/chevron-right.vue';
import { TScheduleDay, TScheduleYear } from '@/_modules/single-row-calendar/types/single-row-calendar.type';

@Component({
  components: { IconChevronLeft, IconChevronRight },
})
export default class SingleRowCalendar extends Vue {
  @Getter('_eventStore/event') event: TEvent;

  public year: TScheduleYear = {};
  public targetDate: Date = null;
  public weekCount: number = 1;
  public month: Date = null;

  @Prop({ type: Array, default: null })
  public readonly eventDays: TEventDay[];

  @Prop({ type: Boolean, default: false })
  public readonly defaultDayOfWeek: boolean;

  public get renderData(): TScheduleDay[] {
    return this.year[this.weekCount].days;
  }

  public get formattedMonth(): string {
    return this.month && this.$moment(this.month).format('MMMM, YYYY');
  }

  public created(): void {
    const year = new Date().getFullYear();

    for (let i = 1; i <= 12; i++) {
      this.fillByWeeks(i, year);
    }

    this.weekCount = this.$moment().locale('en').week();
    this.month = this.year[this.weekCount].days[0].fullDate;

    this.chooseToday();
  }

  public chooseToday(): void {
    const today: Date = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    this.chooseDate({
      dayName: '',
      dayNumber: '',
      isEventDay: false,
      monthName: '',
      weekNumber: undefined,
      fullDate: today
    });
  }

  public fillByWeeks(month: number, year: number): void {

    const fullDate = new Date(year, month - 1);

    const startWeek = this.$moment(fullDate).locale('en').startOf('month').week();
    const endWeek = this.$moment(fullDate).locale('en').endOf('month').week();

    for (let week = startWeek; week <= endWeek; week++) {
      this.year[week] = {
        week: week,
        days: Array(7).fill(0).map((n, i) => {
          const day = this.$moment()
            .locale('en')
            .week(week)
            .startOf(this.defaultDayOfWeek ? 'isoWeek' : 'week')
            .clone()
            .add(n + i, 'day');

          const fullDate = day.toDate();

          return {
            dayNumber: day.format('DD'),
            dayName: day.format('dd'),
            monthName: day.format('MMMM'),
            weekNumber: day.week(week).startOf(this.defaultDayOfWeek ? 'isoWeek' : 'week'),
            isEventDay: this.isEventDate(fullDate),
            fullDate
          };
        })
      };
    }
  }

  public getDaysOfMonth(month: number): string {

    month = +month;
    let totalDays = '31';

    if (month === 11) {
      totalDays = '30';
    } else if (month === 2) {
      totalDays = this.$moment().isLeapYear() ? '29' : '28';
    }

    return totalDays;
  }

  public isEventDate(date: Date): boolean {
    return +this.event.date_start <= +date && +this.event.date_end >= +date;
  }

  public rewind(): void {
    if (this.weekCount > 1) {
      --this.weekCount;
      this.month = this.year[this.weekCount].days[0].fullDate;
    }
  }

  public forward(): void {
    if (this.weekCount < Object.entries(this.year).length - 1) {
      ++this.weekCount;
      this.month = this.year[this.weekCount].days[0].fullDate;
    }
  }

  public chooseDate(day: TScheduleDay): void {
    this.targetDate = day.fullDate;
    this.$emit('targetScheduleDay', day);
  }

  public isFillTargetDay(day: TScheduleDay): boolean {
    return +this.targetDate === +day.fullDate;
  }

  public isCurrentDay(day: TScheduleDay): boolean {

    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date.getTime() === +day.fullDate;
  }

  public startOfMonth(date: Date): number {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return Math.ceil((date.getDate() + firstDay) / 7);
  };

  public isFirstDayRounded(day: TScheduleDay, key: string): boolean {
    if (!this.renderData[+key - 1]) return false;
    return day.isEventDay !== this.renderData[+key - 1].isEventDay;
  }

  public isLastDayRounded(day: TScheduleDay, key: string): boolean {
    if (!this.renderData[+key + 1]) return false;
    return day.isEventDay !== this.renderData[+key + 1].isEventDay;
  }
}

