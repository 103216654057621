var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"side-bar-right",class:{'side-bar-right-open': _vm.isOpen}},[(_vm.contactInfo)?_c('div',{staticClass:"short-content"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('sideBarTabs.notifications.tabTitle'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('sideBarTabs.notifications.tabTitle'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"icon",class:{
        'icon-current': _vm.activeTabName === _vm.TabNames.NOTIFICATIONS
      },on:{"click":function($event){$event.stopPropagation();return _vm.onMenuItemClick(_vm.TabNames.NOTIFICATIONS)}}},[_c('div',{staticClass:"icon-area"},[_c('icon-notifications')],1)]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('sideBarTabs.schedule.tabTitle'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('sideBarTabs.schedule.tabTitle'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"icon",class:{
        'icon-current': _vm.activeTabName === _vm.TabNames.SCHEDULE,
      },on:{"click":function($event){$event.stopPropagation();return _vm.onMenuItemClick(_vm.TabNames.SCHEDULE)}}},[_c('div',{staticClass:"icon-area"},[_c('icon-schedule')],1)]),_c('div',{staticClass:"divider"}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('sideBarTabs.correspondence.tabTitle'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('sideBarTabs.correspondence.tabTitle'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"icon",class:{
        'icon-current': _vm.activeTabName === _vm.TabNames.CORRESPONDENCE
      },on:{"click":function($event){$event.stopPropagation();return _vm.onMenuItemClick(_vm.TabNames.CORRESPONDENCE)}}},[_c('div',{staticClass:"icon-area"},[_c('icon-correspondence'),(_vm.messagesCount - _vm.noticedMessagesCount > 0)?_c('span',{staticClass:"badge-notification"},[_vm._v(" "+_vm._s(_vm.prepareBadgeNotificationNumber(_vm.messagesCount - _vm.noticedMessagesCount))+" ")]):_vm._e()],1)])]):_vm._e(),_c('LastContacts'),_c('div',{staticClass:"fake-container"}),(_vm.contactInfo)?_c('div',{staticClass:"tab-contents"},[_c('div',{staticClass:"close",on:{"click":function($event){return _vm.foldTheSideBar()}}},[_c('div',{staticClass:"icon"},[_c('ew-close-pop-up-icon')],1)]),(_vm.activeTabName === _vm.TabNames.CONTACT)?_c('side-bar-right-tab-contact',{staticClass:"tab-content tab-content-contact",attrs:{"tab-content-title":_vm.$t('sideBarTabs.contact.tabTitle'),"contact-initials":_vm.contactInitials}}):_vm._e(),(_vm.activeTabName === _vm.TabNames.CORRESPONDENCE)?_c('side-bar-right-tab-correspondence',{staticClass:"tab-content tab-content-correspondence",attrs:{"tab-content-title":_vm.$t('sideBarTabs.correspondence.tabTitle'),"contact-initials":_vm.contactInitials}}):_vm._e(),_c('keep-alive',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabName === _vm.TabNames.SCHEDULE),expression:"activeTabName === TabNames.SCHEDULE"}],staticClass:"tab-content tab-content-schedule"},[_c('side-bar-right-tab-schedule')],1)]),(_vm.activeTabName === _vm.TabNames.NOTIFICATIONS)?_c('div',{staticClass:"tab-content tab-content-notifications"},[_c('side-bar-right-tab-notifications')],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"left-shadow"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }