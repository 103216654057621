var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"single-row-calendar"},[_c('button',{staticClass:"day-arrow day-arrow-left",on:{"click":function($event){return _vm.rewind()}}},[_c('IconChevronLeft')],1),_c('div',{staticClass:"month"},[_vm._v(_vm._s(_vm.formattedMonth))]),_c('div',{staticClass:"days-container"},[_c('div',{staticClass:"weeks"},_vm._l((_vm.renderData),function(day,key){return _c('div',{key:key,staticClass:"day",class:[
             {'event-day': day.isEventDay},
             {'first-day-rounded': _vm.isFirstDayRounded(day, key) },
             {'last-day-rounded': _vm.isLastDayRounded(day, key)}
           ],on:{"click":function($event){return _vm.chooseDate(day)}}},[_c('div',{staticClass:"day-name"},[_vm._v(_vm._s(day.dayName.charAt(0)))]),_c('div',{staticClass:"day-number",class:[
                 {'target-day': _vm.isFillTargetDay(day) },
                {'current-day': _vm.isCurrentDay(day) }
                ]},[_c('span',[_vm._v(_vm._s(day.dayNumber))])])])}),0)]),_c('button',{staticClass:"day-arrow day-arrow-right",on:{"click":function($event){return _vm.forward()}}},[_c('IconChevronRight')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }